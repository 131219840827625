<div *ngIf="!initializing" style="display:flex; height: 100vh; overflow: hidden;">

  <div class="navigation-container">
    @if (preferences.dock == 'start' || preferences.dock == undefined) {
      <atw-dock class="dock-start"/>
    }
    @if(!isApp){
    <app-navigation/>
    }
  </div>

  @if (preferences.dock == 'end' || preferences.dock=='bottom') {
    <atw-dock class="dock-{{preferences.dock}}"/>
  }


  <div style="height: 100vh; flex-grow: 1; overflow: hidden auto">
    <router-outlet></router-outlet>
  </div>
</div>

<atw-upload-toast
  style="display:block;position: fixed;bottom: 10px;right:10px;"/>

<div *ngIf="initializing">
  <app-loading/>

</div>
