import { Injectable } from '@angular/core';
import { deleteToken, getToken, Messaging, onMessage } from '@angular/fire/messaging';
import { log } from '../utils/logger';
import { NotificationEvents } from '../../events/notification.events';
import { NotificationDto } from '../../data/objects/notification.dto';
import { NotificationRepository } from '../../data/repository/notification.repository';
import { ToastService } from './toast.service';
import { ChatEvents } from 'src/app/events/chat.events';
import { MessageDto } from 'src/app/data/objects/chat.dtos';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private msg: Messaging,
    private notifEvents: NotificationEvents,
    private chatEvents: ChatEvents,
    private toastr: ToastService,
    private notifRepo: NotificationRepository,
  ) {}

  requestPermission() {
    Notification.requestPermission()
      .then((notificationPermissions: NotificationPermission) => {
        if (notificationPermissions === 'granted') {
          this.registerWorker();
        }
        if (notificationPermissions === 'denied') {
        }
      })
      .catch()
      .finally();
  }

  registerWorker() {
    navigator.serviceWorker
      .register('firebase-messaging-sw.js', { type: 'module' })
      .then((serviceWorkerRegistration) => {
        getToken(this.msg, {
          vapidKey:
            'BA0z-UOuJWPf_EUIvuk-I6BmvwzggfUZPJGIhIVk0_Rc8UoFjwWCRdP4HEOUzyMGgFbG1sEDalY2KU2FX_0PIwM',
          serviceWorkerRegistration: serviceWorkerRegistration,
        }).then((token) => {
          log('registered', token);
          let push_token = localStorage.getItem('push_token');
          if (push_token == null) {
            localStorage.setItem('push_token', token);
            this.notifRepo.register(token);
          }
        });
      });
  }

  receiveMessage() {
    log('listen to messages', this.msg);
    if (!this.msg) return;
    onMessage(this.msg, (payload) => {
      log('message received', payload);
      if (payload?.data?.['type'] == 'MESSAGE') {
      this.chatEvents.emitNewMessage(payload.data as unknown as MessageDto)
      } else {
        let notifDto = new NotificationDto();
        notifDto = { ...notifDto, ...payload.data };
        if (payload.notification) this.notifEvents.emitNewNotification(notifDto);
        this.toastr.notification(payload.notification?.title);
      }
    });
  }

  async deleteToken() {
    await deleteToken(this.msg);
  }
}
